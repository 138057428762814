.info {
  background-color: #0085ff;
  width: 240px;
  max-width: 90%;
}

.disclaimer {
  padding: 8px;
  position: fixed;
  bottom: 0;
  right: 0;
  font-size: calc(4px + 1vmin);
}
