.App {
  text-align: center;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.darkMode {
  padding: 16px;
  position: fixed;
  top: 0;
  right: 0;
  font-size: calc(4px + 1vmin);
}

.bitcoinLogo {
  min-width: 400px;
}

img {
  max-width: 100%;
}

button {
  font-family: "GT Walsheim Medium", Arial, Helvetica, sans-serif;
  font-weight: bold;
  width: 100%;
  height: 56px;
  cursor: pointer;
  border-radius: 4px;
  border-width: 2px;
  border-style: solid;
  border-color: transparent;
  border-image: initial;
  outline: none;
  font-size: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
}
