.confirm {
  background-color: #388e3c;
  color: rgb(255, 255, 255);
}

.reject {
  background-color: #ef5350;
  color: rgb(255, 255, 255);
}

.buttons {
  display: flex;
  width: 300px;
  max-width: 90%;
}

.buttons :not(:last-child) {
  margin-right: 8px;
}
